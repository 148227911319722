/* ----------------------------------------------------------------------------
automatically play a video once it's mostly in the viewport
---------------------------------------------------------------------------- */
export default class PlayOnScroll extends HTMLElement {
  playElements() {
    const observedElements = this.querySelectorAll('video');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          const element = entry.target;
          if (element.paused === true) {
            element.currentTime = 0;
            element.play();
          }
        }
      });
    });
    observedElements.forEach((element) => {
      observer.observe(element);
    });
  }

  connectedCallback() {
    this.playElements();
  }
}

window.customElements.define('play-on-scroll', PlayOnScroll);
