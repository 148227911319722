/* ----------------------------------------------------------------------------
switch between panes within a single page
---------------------------------------------------------------------------- */

export default class TabGroup extends HTMLElement {
  changeTab(tabID) {
    this.tabContents.forEach((element) => {
      if (element.id === tabID) {
        element.classList.remove('display', '--none');
      } else {
        element.classList.add('display', '--none');
      }
    });
    this.tabControls.forEach((element) => {
      const currentElement = element;
      if (element.getAttribute('aria-controls') === tabID) {
        currentElement.classList.add('--inverted');
        currentElement.ariaSelected = true;
      } else {
        currentElement.classList.remove('--inverted');
        currentElement.ariaSelected = false;
      }
    });
  }

  render() {
    this.tabControls.forEach((element) => {
      element.addEventListener('click', (e) => {
        if (element.ariaSelected !== 'true') {
          this.changeTab(element.getAttribute('aria-controls'));
        }
      });
    });
  }

  connectedCallback() {
    this.tabControls = this.querySelectorAll('[role="tab"]');
    this.tabContents = this.querySelectorAll('[role="tabpanel"]');
    this.render();
  }
}

window.customElements.define('tab-group', TabGroup);
