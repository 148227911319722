/* ----------------------------------------------------------------------------
add close-on-click-outside support to inner <details>
---------------------------------------------------------------------------- */

export default class DetailsMenu extends HTMLElement {
  closeable() {
    document.addEventListener('click', (e) => {
      const isClickInside = this.contains(e.target);
      const isOpen = this.querySelector('details[open]');
      if (!isClickInside && isOpen) {
        this.querySelector('details[open]').removeAttribute('open');
      }
    });
  }

  connectedCallback() {
    this.closeable();
  }
}

window.customElements.define('details-menu', DetailsMenu);
