/* ----------------------------------------------------------------------------
( 4.354 weeks/month × 5 meetings/week × 15 minutes/meeting
× 1/60 hours/minute × 10 team members × $100 average team member rate/hour )
− ( 4.354 weeks/month × 5 check-ins/week × 1.4 minutes/check-in
× 1/60 hours/minute × $100 average team member rate/hour )
− ( $5 per user/month × 10 team members )
---------------------------------------------------------------------------- */

export default class SavingsPreview extends HTMLElement {
  static get observedAttributes() {
    return ['headcount', 'price'];
  }

  get headcount() {
    return this.getAttribute('headcount');
  }

  get price() {
    return this.getAttribute('price');
  }

  calculate() {
    const qty = 5;
    const time = 15;
    const size = this.headcount;
    const salary = 100;
    let price = this.price.replace('$', '');
    if (typeof price !== 'number') { price = 18; }

    // Assume 4.354 weeks/month, 1.5 minutes to fill out a check-in
    this.savingsMonthly = (
      // Cost of the meetings in team member time
      4.354 * qty * time * (salary / 60) * size
    ) - (
      // Cost of checking in in team member time
      4.354 * qty * 1.5 * (salary / 60) * size
    ) - (
      // Cost of the app
      price * size
    );
    this.monthly = Math.round(this.savingsMonthly);
    this.innerHTML = this.monthly.toLocaleString('en-US');
  }

  attributeChangedCallback(attribute, previousValue, currentValue) {
    this.calculate();
  }

  connectedCallback() {
    this.calculate();
  }
}

window.customElements.define('savings-preview', SavingsPreview);
